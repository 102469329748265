<template>
  <div class="v-list-product-items">
    <ul>
      <li class="mb-2" v-for="{ item } in items" :key="item.id">
        <div class="product-item-name" v-html="item.name"></div>
        <div class="product-item-description small mb-3" v-html="item.description"></div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'VListProductItems',
  props: {
    items: Array
  }
}
</script>

<style lang="scss" scoped>
.v-list-product-items {
  color: $white !important;
  ul {
    position: relative;
    list-style: none;
    padding-left: 20px;
    li {
      &:before {
        position: absolute;
        left: 0;
        margin-top: 4px;
        content: '';
        width: 4px;
        height: 15px;
        background-color: $orange;
      }
    }
  }
}
.list-product-toggle {
  background-color: transparentize($white, .9);
  margin: 0 -20px;
  padding: 10px 20px;
}
</style>
