<template>
  <div class="v-list-product-items-collapsable">
    <div class="list-product-toggle" :class="{ 'd-md-none': !alwaysCollapsable }">
      <VCollapse>
        <template v-slot:title>
          <div class="d-flex m-0 text-white">
            Ver todos os benefícios
            <span class="ml-auto"><span class="fal fa-chevron-down"></span></span>
          </div>
        </template>
        <template v-slot:body>
          <VListProductItems :items="items" class="mt-3"/>
        </template>
      </VCollapse>
    </div>
    <div class="d-none" :class="{ 'd-md-block': !alwaysCollapsable }">
      <VListProductItems :items="items" class="mt-3"/>
    </div>
  </div>
</template>

<script>
import VCollapse from '@/common/components/VCollapse'
import VListProductItems from '@/modules/checkout/components/VListProductItems'
export default {
  name: 'VListProductItemsCollapsable',
  components: { VListProductItems, VCollapse },
  props: {
    items: Array,
    alwaysCollapsable: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.list-product-toggle {
  background-color: transparentize($white, .9);
  margin: 0 -20px;
  padding: 10px 20px;
}
</style>
