<template>
  <div class="v-plan-card" :class="{ condensed }">
    <div class="plan-title">
      <h5 class="mb-0 font-weight-normal" v-if="!condensed">Jornal A Tribuna</h5>
      <h1>{{ plan.name }}</h1>
    </div>
    <VPrice :condensed="condensed" :cycle="plan.billing_cycle" :price="plan.override_price"/>
    <VListProductItemsCollapsable
      class="mt-md-5 mt-3"
      :items="plan.product.product_items"
      v-if="!hideList"
      :always-collapsable="alwaysCollaspable"
    />
    <div class="mt-5 plan-image d-none d-md-block" v-if="!hideImage">
      <img src="@/assets/images/examples/plan-image-example.png" alt="Plan Image Example">
    </div>
  </div>
</template>

<script>
import VPrice from '@/modules/checkout/components/VPrice'
import VListProductItemsCollapsable from '@/modules/checkout/components/VListProductItemsCollapsable'

export default {
  name: 'VPlanCard',
  props: {
    plan: Object,
    alwaysCollaspable: {
      type: Boolean,
      default: false
    },
    hideList: {
      type: Boolean,
      default: false
    },
    hideImage: {
      type: Boolean,
      default: false
    },
    condensed: {
      type: Boolean,
      default: false
    }
  },
  components: {
    VListProductItemsCollapsable,
    VPrice
  }
}
</script>

<style lang="scss" scoped>
.v-plan-card {
  background-color: $primary;
  padding: 60px 40px;
  &.condensed {
    padding: 30px 25px;
    h1 {
      font-size: 1.4em;
    }
  }
}
.plan-title {
  color: $white;
}
.plan-image img {
  max-width: 100%;
}

@include media-breakpoint-down(sm) {
  .v-plan-card {
    padding: 30px 20px 0;
  }
  .plan-title {
    h5 {
      font-size: 1em;
    }
    h1 {
      font-size: 1.5em;
    }
  }
}
</style>
