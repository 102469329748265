<template>
  <div class="v-collapse wrap-collabsible">
    <input :id="id" class="toggle" type="checkbox">
    <label :for="id" class="lbl-toggle">
      <slot name="title">Clique para expandir</slot>
    </label>
    <div class="collapsible-content">
      <div class="content-inner">
        <slot name="body"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VCollapse',
  computed: {
    id () {
      return `v-collapse-${this._uid}`
    }
  }
}
</script>

<style lang="scss" scoped>
input[type='checkbox'] {
  display: none;
}
.lbl-toggle {
  display: block;
  cursor: pointer;
  transition: all 0.25s ease-out;
}
.collapsible-content {
  max-height: 0px;
  overflow: hidden;
  transition: max-height .25s ease-in-out;
}
.toggle:checked + .lbl-toggle + .collapsible-content {
  max-height: 100vh;
}
.toggle:checked + .lbl-toggle::before {
  transform: rotate(90deg) translateX(-3px);
}
.lbl-toggle {
  margin: 0;
}
</style>
