<template>
  <div class="v-price" :class="{ condensed }">
    <div class="text-light">No plano <strong>{{ frequency.presentation.label }}</strong></div>
    <div class="d-flex">
      <div class="currency-sign text-white mr-1">R$</div>
      <div class="currency-value align-self-end text-orange">{{ amount.value }}</div>
      <div class="currency-cents align-self-end text-orange">,{{ amount.cents }}</div>
      <div class="plan-frequency align-self-end text-white">/mês</div>
    </div>
  </div>
</template>

<script>
import { Duration } from 'luxon'

const humanPeriods = {
  1: { name: 'monthly', label: 'mensal' },
  2: { name: 'bimonthly', label: 'bimestral' },
  3: { name: 'quarterly', label: 'trimestral' },
  4: { name: 'semester', label: 'semestral' },
  5: { name: 'yearly', label: 'anual' }
}

export default {
  name: 'Price',
  props: {
    price: {
      type: Number,
      default: 0
    },
    cycle: {
      type: String,
      default: 'P1M'
    },
    condensed: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    amount () {
      const value = this.price / this.frequency.months
      return {
        value: Math.floor(value / 100),
        cents: `${Math.floor(value % 100)}`.padStart(2, '0')
      }
    },
    frequency () {
      const months = Duration.fromISO(this.cycle).months
      return {
        months: months,
        presentation: humanPeriods[months] ?? humanPeriods[1]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$currency-font-weight: 500;
.currency-sign {
  font-size: 1.3em;
  line-height: 1.4em;
  font-weight: $currency-font-weight;
}
.currency-value {
  font-size: 3.5em;
  line-height: .9em;
  font-weight: $currency-font-weight;
}
.currency-cents, .plan-frequency {
  font-size: 2.3em;
  line-height: 1em;
  font-weight: $currency-font-weight;
}
.plan-frequency {
  font-weight: 300;
}
.v-price {
  &.condensed {
    .currency-sign { font-size: 1em; }
    .currency-value { font-size: 2.4em; }
    .currency-cents, .plan-frequency { font-size: 1.4em; }
  }
}
@include media-breakpoint-down(sm) {
  .currency-sign { font-size: 1em; }
  .currency-value { font-size: 2.6em; }
  .currency-cents, .plan-frequency { font-size: 1.4em; }
}
</style>
